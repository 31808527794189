
import React from 'react'
import { MediaSection } from '../../system/settings/pageData'
import Layout from '../../views/templates/adminLayout'
import MediaView from '../../views/media/index'

const PageInfo = {
    page: MediaSection.section,
    icon: MediaSection.icon,
}

const Media = () => {
    return (
        <Layout PageInfo={PageInfo} minimumRole={MediaSection.minimumRole}>
            <MediaView />
        </Layout>
    )
}

export default Media
